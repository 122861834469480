import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Paper, Grid, TextField, Button, Box, Avatar, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, CircularProgress, Alert } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputLabel,
    Icon,
  } from '@mui/material';
  import UploadFileIcon from '@mui/icons-material/UploadFile';
  
const StudentProfile = () => {
    const { id } = useParams();
    const [student, setStudent] = useState(null);
    const [editField, setEditField] = useState(null);
    const [updatedStudent, setUpdatedStudent] = useState({});
    const [previousState, setPreviousState] = useState({});
    const [lastModified, setLastModified] = useState({ by: "", date: "",file:"" });
    const [loading, setLoading] = useState(false); // To track loading state
    const [message, setMessage] = useState(""); // For success/error message
    const containerRef = useRef();

    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
const [attachment, setAttachment] = useState(null);

    useEffect(() => {
        const fetchStudent = async () => {
            const authToken = localStorage.getItem('authToken');
            const response = await fetch(`https://eap.ethernet.edu.et/api/getStudents/${id}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
            if (response.ok) {
                const jsonData = await response.json();
                setStudent(jsonData);
                setUpdatedStudent(jsonData);
                setPreviousState(jsonData); // Store the initial state for cancellation
                setLastModified({ by: jsonData.modified_by, date: jsonData.modified_at ,file: jsonData.attachment_url}); // Set last modified data
            } else {
                console.error("Failed to fetch student");
            }
        };
        fetchStudent();
    }, [id]);

    useEffect(() => {
        // Close editor if clicked outside the editing field
        const handleClickOutside = (e) => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                setEditField(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedStudent((prev) => ({ ...prev, [name]: value }));
    };

    const handleSave = () => {
        setMessage("");      // Clear any prior message
        setAttachment(null); // Clear old file
        setShowAttachmentModal(true); // Open modal fresh
      };
      

      const submitWithAttachment = async () => {
        setLoading(true);
        setMessage("");
      
        try {
          const authToken = localStorage.getItem("authToken");
      
          const formData = new FormData();
          formData.append("data", JSON.stringify(updatedStudent)); // ✅ Correct
          formData.append("attachment", attachment);               // ✅ Correct
      
          const response = await fetch(`https://eap.ethernet.edu.et/api/updateStudentWithAttachment/${id}`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authToken}`,     // ✅ NO 'Content-Type'
              Accept: "application/json",
            },
            body: formData,
          });
      
          setLoading(false);
      
          if (response.ok) {
            const result = await response.json();
            setStudent(updatedStudent);
            setPreviousState(updatedStudent);
            setEditField(null);
            setMessage("Student updated successfully!");
            setAttachment(null);
            setShowAttachmentModal(false);
          } else {
            const errorData = await response.json();
            const errorMessage = errorData.errors
              ? "Please fix the following errors: " +
                Object.values(errorData.errors).flat().join(", ")
              : "Failed to update student. Please try again.";
            setMessage(errorMessage);
          }
        } catch (error) {
          setLoading(false);
          setMessage("Unexpected error occurred.");
        }
      };
       
      
    const handleCancel = () => {
        setUpdatedStudent(previousState); // Revert to the previous state on cancel
        setEditField(null);
    };



    if (!student) {
        return <Typography>Loading...</Typography>;
    }

    return (



        
        <Container maxWidth={false} sx={{ padding: 0, width: "100%", mt: 4 }} ref={containerRef}>
            {/* Display message */}
            {message && (
                <Alert severity={message.includes('successfully') ? 'success' : 'error'}>{message}</Alert>
            )}
            <Typography variant="h5" fontWeight="bold" color="primary" mb={2}

                sx={{
                    width: '100%',
                    borderBottom: '1px solid #ccc',
                    mb: 2,
                    fontWeight: 'bold'
                }}>
                Student Detail
            </Typography>
            {/* Main Profile Layout: Profile Picture on the Left and Persoernal Information Table on the Right */}
            <Grid container spacing={3} sx={{ mt: 3 }}>
                {/* Left Column: Profile Picture */}
                <Grid item xs={12} md={2}>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 2,
                            borderRadius: 1,
                            backgroundColor: "#f9f9f9",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                width: '100%',
                                borderBottom: '1px solid #ccc',
                                mb: 2,
                                fontWeight: 'bold'
                            }}
                        >
                            <Typography variant="h7" fontWeight="bold" color="primary" mb={2}>
                                Perofile picture
                            </Typography>    </Typography>
                        <Box
                            sx={{
                                width: 140,
                                height: 140,
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mx: 'auto'  // This centers the Box horizontally
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '4px'
                                }}
                                src={student.gender === "Female" ? "https://eap.ethernet.edu.et/female_s_avatar.png" : "https://eap.ethernet.edu.et/male_s_avatar.png"}
                            />
                        </Box>
                    </Paper>
                </Grid>


                {/* Right Column: Personal Information Table */}
                <Grid item xs={12} md={10}>
                    <Paper elevation={3} sx={{ p: 4, borderRadius: 1, backgroundColor: "#f9f9f9", width: '100%' }}>
                        <Typography variant="h6" fontWeight="bold" color="primary" mb={2}>
                            Personal Information
                        </Typography>
                        <TableContainer component={Paper} sx={{ borderRadius: 1, boxShadow: 1, width: '100%' }}>
                            <Table>
                                <TableBody>
                                    {[
                                        { label: 'First Name', key: 'first_name' },
                                        { label: 'Father Name', key: 'father_name' },
                                        { label: 'Grandfather Name', key: 'grand_father_name' },
                                        { label: 'Username', key: 'username' },
                                        { label: 'Gender', key: 'gender' },
                                        { label: 'Phone Number', key: 'phone_number' },
                                        { label: 'Is Blind', key: 'is_blind' },
                                        { label: 'Is Deaf', key: 'is_deaf' }
                                    ].map(({ label, key }) => (
                                        <TableRow key={key}>
                                            <TableCell>
                                                <Typography fontWeight="bold">{label}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {editField === key ? (
                                                    <TextField
                                                        fullWidth
                                                        name={key}
                                                        value={updatedStudent[key] || ''}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                ) : (
                                                    <Typography>{updatedStudent[key] || student[key] || "-"}</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {key !== 'username' && (
                                                    editField === key ? (
                                                        <IconButton size="small" onClick={() => setEditField(null)}>
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton size="small" onClick={() => setEditField(key)}>
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    )
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>

            {/* Educational Background and Result Information Cards side by side */}
            <Grid container spacing={3} sx={{ mt: 3 }}>
                {/* Left Column: Educational Background */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 4, borderRadius: 3, backgroundColor: "#f9f9f9" }}>
                        <Typography variant="h6" fontWeight="bold" color="primary" mb={2}>
                            Educational Background
                        </Typography>
                        <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: 2, width: '100%' }}>
                            <Table>
                                <TableBody>
                                    {[
                                        { label: 'Institution Name', key: 'institution_name' },
                                        { label: 'Student ID', key: 'student_id' },
                                        { label: 'Exam Topic', key: 'exam_topic_name' },
                                        { label: 'Exam Center', key: 'exam_center_name' },
                                        { label: 'Enrollment', key: 'enrollment' },
                                        { label: 'Department', key: 'department' },
                                        { label: 'Campus', key: 'campus' },
                                        { label: 'Latest GPA', key: 'latest_gpa' },
                                        { label: 'Admission Year', key: 'admission_year' },
                                        { label: 'Admission Month', key: 'admission_month' }
                                    ].map(({ label, key }) => (
                                        <TableRow key={key}>
                                            <TableCell>
                                                <Typography fontWeight="bold">{label}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {editField === key ? (
                                                    <TextField
                                                        fullWidth
                                                        name={key}
                                                        value={updatedStudent[key] || ''}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                ) : (
                                                    <Typography>{updatedStudent[key] || student[key] || "-"}</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {!(key === 'exam_topic_name' || key === 'department' || key === 'exam_center_name' || key === 'campus' || key === "enrollment" || key === "latest_gpa" || key === "admission_year" || key === "admission_month") && (
                                                    editField === key ? (
                                                        <IconButton size="small" onClick={() => setEditField(null)}>
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton size="small" onClick={() => setEditField(key)}>
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    )
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>

                {/* Right Column: Result Information and Last Modified */}
                <Grid item xs={12} md={6}>
                    {/* Result Information */}
                    <Paper elevation={3} sx={{ p: 4, borderRadius: 3, backgroundColor: "#f9f9f9", mb: 3 }}>
                        <Typography variant="h6" fontWeight="bold" color="primary" mb={2}>
                            Result Information
                        </Typography>
                        <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: 2, width: '100%' }}>
                            <Table>
                                <TableBody>
                                {[
  { label: 'Exam Year', key: 'exam_year' },
  { label: 'Total Score', key: 'total_score' },
  { label: 'Status', key: 'status' },
  { label: 'Pass Mark', key: 'pass_mark' },
  { label: 'is desqualified', key: 'is_desqualified' }
].map(({ label, key }) => (
  <TableRow key={key}>
    <TableCell>
      <Typography fontWeight="bold">{label}</Typography>
    </TableCell>
    <TableCell>
      {editField === key ? (
        <TextField
          fullWidth
          name={key}
          value={updatedStudent[key] || ''}
          onChange={handleChange}
          variant="outlined"
          size="small"
        />
      ) : key === 'status' ? (
        <Box>
          {Number(updatedStudent.total_score) >= 50 ? (
            <Box
              sx={{
                display: 'inline-block',
                px: 2,
                py: 0.5,
                bgcolor: 'success.light',
                color: 'success.dark',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                borderRadius: '12px',
                textTransform: 'uppercase',
              }}
            >
              Passed
            </Box>
          ) : (
            <Box
              sx={{
                display: 'inline-block',
                px: 2,
                py: 0.5,
                bgcolor: 'error.light',
                color: 'error.dark',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                borderRadius: '12px',
                textTransform: 'uppercase',
              }}
            >
              Failed
            </Box>
          )}
        </Box>
      ) : key === 'pass_mark' ? (
        <Typography>50</Typography> // ✅ Static Pass Mark
      ) : (
        <Typography>{updatedStudent[key] || student[key] || "-"}</Typography>
      )}
    </TableCell>
  </TableRow>
))}

                                  
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                    {/* Last Modified Info */}
                    <Paper elevation={3} sx={{ p: 4, borderRadius: 3, backgroundColor: "#f9f9f9" }}>
                        <Typography variant="h6" fontWeight="bold" color="primary" mb={2}>
                            Last Modified
                        </Typography>
                        <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: 2, width: '100%' }}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography fontWeight="bold">Modified By</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{lastModified.by || "-"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography fontWeight="bold">Date and time</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{lastModified.date || "-"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography fontWeight="bold">Attachments</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{lastModified.file || "-"}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>

            {editField && (
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Button onClick={handleSave} variant="contained" color="primary" sx={{ mr: 2 }}>
                                Save
                            </Button>
                            <Button onClick={handleCancel} variant="outlined" color="secondary">
                                Cancel
                            </Button>
                        </>
                    )}
                </Box>
            )}



<Dialog
  open={showAttachmentModal}
  fullWidth
  maxWidth="sm"
  disableEscapeKeyDown
  PaperProps={{
    sx: {
      borderRadius: 4,
      p: 2,
      backgroundColor: '#fff',
      boxShadow: 5,
    },
  }}
>
  <DialogTitle
    sx={{
      fontWeight: 'bold',
      fontSize: '1.3rem',
      color: 'primary.main',
      borderBottom: '1px solid #eee',
      pb: 1,
      mb: 2,
    }}
  >
    📎 Upload Supporting Document
  </DialogTitle>

  <DialogContent>
    <Typography variant="body1" sx={{ mb: 1 }}>
      Please attach a <strong>PDF or image</strong> explaining the reason for your change request.
    </Typography>

    <InputLabel sx={{ mb: 1, color: 'text.secondary' }}>
      Select file (PDF/Image)
    </InputLabel>

    <Button
      variant="outlined"
      component="label"
      startIcon={<UploadFileIcon />}
      fullWidth
      sx={{
        my: 2,
        borderRadius: 2,
        textTransform: 'none',
        backgroundColor: '#f9f9f9',
        border: '1px dashed #ccc',
        '&:hover': {
          backgroundColor: '#f1f1f1',
        },
      }}
    >
      Upload File
      <input
        hidden
        type="file"
        accept="application/pdf,image/*"
        onChange={(e) => setAttachment(e.target.files[0])}
      />
    </Button>

    {attachment && (
      <Box mt={2}>
        <Typography variant="body2" fontWeight="bold">
          📂 Selected: {attachment.name}
        </Typography>

        {attachment.type.startsWith('image') && (
          <Box mt={2} sx={{ textAlign: 'center' }}>
            <img
              src={URL.createObjectURL(attachment)}
              alt="preview"
              style={{ maxWidth: '100%', borderRadius: 8, boxShadow: '0px 2px 10px rgba(0,0,0,0.1)' }}
            />
          </Box>
        )}
      </Box>
    )}

    {/* Display validation or server error inside modal */}
    {message && (
      <Alert
        severity={message.includes('successfully') ? 'success' : 'error'}
        sx={{ mt: 3 }}
      >
        {message}
      </Alert>
    )}
  </DialogContent>

  <DialogActions sx={{ mt: 2 }}>
    <Button
      onClick={() => setShowAttachmentModal(false)}
      color="error"
      variant="outlined"
      sx={{ textTransform: 'none', borderRadius: 2 }}
    >
      Cancel
    </Button>

    <Button
      onClick={submitWithAttachment}
      color="primary"
      variant="contained"
      disabled={loading || !attachment}
      sx={{ textTransform: 'none', borderRadius: 2 }}
    >
      {loading ? <CircularProgress size={20} /> : 'Submit Changes'}
    </Button>
  </DialogActions>
</Dialog>


        </Container>
    );
};

export default StudentProfile;
