import React, { useEffect, useState } from 'react';
import { Table,
  Container,TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const DataProgressTable = () => {
  const [data, setData] = useState({
    private: { approved: 0, rejected: 0, not_submitted: 0 },
    public: { approved: 0, rejected: 0, not_submitted: 0 }
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
        const authToken = localStorage.getItem('authToken');

      setLoading(true);
      try {
        const response = await fetch('https://eap.ethernet.edu.et/api/data-progress'
        , {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (

    <Container maxWidth="lg" sx={{ ml:0, mt:10,width: 750}} >
    <h4>Data Sumbission Stastics </h4>
    <TableContainer component={Paper} sx={{ maxWidth: 650, margin: 'auto', mt: 4 }}>
      
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><b>Institution Type</b></TableCell>
            <TableCell align="right"><b>Approved</b></TableCell>
            <TableCell align="right"><b>Rejected</b></TableCell>
            <TableCell align="right"><b>Not Submitted</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {['private', 'public'].map((type) => (
            <TableRow key={type}>
              <TableCell component="th" scope="row">
             <b>   {type.charAt(0).toUpperCase() + type.slice(1)}</b>
              </TableCell>
              <TableCell align="right">{data[type].approved}</TableCell>
              <TableCell align="right">{data[type].rejected}</TableCell>
              <TableCell align="right">{data[type].not_submitted}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
  );
};

export default DataProgressTable;
