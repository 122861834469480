import React, { useEffect, useState 
} from 'react';
import AllStudentList from './AllStudentsList'; // Assuming ExamTopics component is created as per previous instructions
import Button from '@mui/material/Button';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
   Typography,
  TablePagination,
  TextField,
  Box,
  CircularProgress,
} from '@mui/material';

const Rolespage = () => {
  return (
    <div>
 <Typography variant="h5" fontWeight="bold">
          🎓 All Student List
        </Typography>       <AllStudentList />
    </div>
  );
};

export default Rolespage;
