import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TablePagination,
  TextField,
  Box,
  CircularProgress
} from '@mui/material';
import AlertDialog from './AlertDialog';
import { debounce } from 'lodash';

const StudentTable = () => {
  const [students, setStudents] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const perPage = 20;
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setPage(0);
    }, 500),
    []
  );

  const handleSearchChange = (event) => {
    debouncedSearch(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await fetch(
          `https://eap.ethernet.edu.et/api/students?search=${search}&page=${page + 1}`,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
          }
        );
        if (response.ok) {
          const jsonData = await response.json();
          setStudents(jsonData.data);
          setTotal(jsonData.total);
        } else {
          console.error("Failed to fetch students");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData();
  }, [search, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDetailsClick = (id) => {
    navigate(`/getStudents/${id}`);
  };

  return (
    <Box sx={{ height: '100vh', width: '84vw', p: 2, boxSizing: 'border-box' }}>
<Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
<TextField
          onChange={handleSearchChange}
          placeholder="Search by username"
          size="small"
          sx={{ width: 250 }}
        />
      </Box>

      <Paper sx={{ height: 'calc(100% - 80px)', display: 'flex', flexDirection: 'column' }}>
        <TableContainer sx={{ flex: 1 }}>
          <Table stickyHeader aria-label="student table">
            <TableHead>
              <TableRow>
              <TableCell><strong>Username</strong></TableCell>
              <TableCell><strong>First Name</strong></TableCell>
              <TableCell><strong>Father Name</strong></TableCell>
                <TableCell><strong>Grand Father Name</strong></TableCell>
                <TableCell><strong>Gender</strong></TableCell>
                <TableCell><strong>Enrollment Type</strong></TableCell>
                <TableCell><strong>Institution Name</strong></TableCell>
                 <TableCell><strong>Exam Topic</strong></TableCell>
                 <TableCell><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                students.map((student) => (
                  <TableRow key={student.id} hover>
                                        <TableCell>{student.username}</TableCell>

                    <TableCell>{student.first_name}</TableCell>
                    <TableCell>{student.father_name}</TableCell>
                    <TableCell>{student.grand_father_name}</TableCell>
                    <TableCell>{student.gender}</TableCell>
                    <TableCell>{student.enrollment_type}</TableCell>
                    <TableCell>{student.institution.name}</TableCell>
                     <TableCell>{student.examtopic.name}</TableCell>
                     <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleDetailsClick(student.id)}
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[perPage]}
          component="div"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={perPage}
        />
      </Paper>

      <AlertDialog
        open={open}
        onClose={handleClose}
        title="Temporary Function Disablement"
        message="This function is disabled temporarily."
      />
    </Box>
  );
};

export default StudentTable;
